const ParecerRisco = Object.freeze({
    '1' : 'Trabalho em Altura                                         ',
    '2' : 'Trabalho em Espaço Confinado                               ',
    '3' : 'Trabalho com Eletricidade                                  ',
    '4' : 'Trabalho em Operação de Máquinas e Equipamentos            ',
    '5' : 'Manipulação de Alimentos                                   ',
    '6' : 'Trabalho em Condições Hiperbáricas                         ',
    '7' : 'Trabalho com Exposição a Radiação ou Material Radioativo   ',
    '8' : 'Trabalho com Eletricidade de Baixa Tensão                  ',
    '9' : 'Trabalho com Empilhadeira e/ou Ponte Rolante               ',
    '10' : 'Trabalho com Inflamáveis e Combustíveis                    ',
    '11' : 'Trabalho à Céu Aberto                                      ',
    '12' : 'Trabalho Energizado                                        ',
    '13' : 'Trabalho com Empilhadeira                                  ',
    '14' : 'Trabalho com Ponte Rolante                                 ',
    '15' : 'Uso de Proteção Respiratória                               ',
    '16' : 'Operar Veículos Industriais                                ',
    '17' : 'Condução de Veículos                                       ',
    '18' : 'Trabalho com Manuseio de Cargas                            ',
    '19' : 'Trabalho com Movimentação de Cargas                        ',
    '20' : 'Trabalho com Postura Incorreta                             ',
    '21' : 'Trabalho Aquaviário                                        ',
    '22' : 'Manuseio de uma arma de fogo                               ',
    '23' : 'Trabalho em Regime Offshore                                ',
})

export default ParecerRisco;
