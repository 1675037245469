const DecretoRisco = Object.freeze({
  '1': 'DECRETO 3048',
  '2': 'ACGIH',
  '3': 'NR 15',
  '4': 'NR 16',
  '5': 'NR 17',
  '6': 'NBR',
  '7': 'NHO 01'
})

export default DecretoRisco;
