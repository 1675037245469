const MedicaoRisco = Object.freeze({
  '1': 'LTCAT',
  '2': 'PGR',
  '3': 'Insalubridade',
  '4': 'Periculosidade',
  '5': 'AET',
  
})

export default MedicaoRisco;
