<template>
    <AppPaginatedGridNested
        :service="service"
        parentName="risco"
        :defaultProps="{ riscoId: form?.id }"
        :expander="false"
        labelBotaoAdicionarItem="Descrição risco"
        :isValid="isValid"
        :queryParams="queryParams"
        @onValidate="onValidate"
    >
        <template #header>
            <h5>{{ `RISCO: ${form?.nome?.toUpperCase()}` }}</h5>
        </template>
        <template #columns>
            <Column field="descricaoRisco" header="DescricaoRisco">
                <template #editor="{ data, field }">
                    <DropdownDescricaoRisco v-model="data[field]" @update:modelValue="data.descricaoRiscoId = data[field]?.id" />
                </template>
                <template #body="slotProps">
                    {{ slotProps?.data?.descricaoRisco?.nome }}
                </template>
            </Column>
            <Column field="createdAt" :sortable="true" sortField="created_at" header="Criado">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.createdAt) }}
                </template>
            </Column>
        </template>
    </AppPaginatedGridNested>
</template>

<script>
import DropdownDescricaoRisco from '../../descricao-risco/components/DropdownDescricaoRisco.vue';
import NestedServiceSesmt from '@/services/NestedServiceSesmt';
import { showError } from '../../../utils/Toast';

export default {
    props: {
        form: {
            type: Object,
            required: true
        }
    },
    components: {
        DropdownDescricaoRisco
    },
    data() {
        return {
            isValid: true
        };
    },
    created() {
        this.service = new NestedServiceSesmt('/risco-descricao-risco');
    },
    computed: {
        queryParams() {
            return {
                riscoId: this.$route.params.id
            };
        }
    },
    methods: {
        onValidate(data) {
            if (!data?.descricaoRiscoId) {
                const error = { message: 'Informe a descrição de risco à ser vinculado!' };
                showError(this.$toast, error);
                this.isValid = false;
            }
            this.isValid = true;
        }
    }
};
</script>

<style lang="scss">
</style>