const TrajetoriaRisco = Object.freeze({
    NAO_SE_APLICA: 0,
    PELO_AR: 1,
    POR_CONTATO: 2,
    PELO_AR_E_POR_CONTATO: 3,
    VIAS_RESPIRATORIAS: 4,
    VIA_DERMAL: 5,
    VIAS_RESPIRATORIAS_E_VIA_DERMAL: 6
});

export default TrajetoriaRisco;
